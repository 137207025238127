/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Prismatic opened its doors in the Spring of 2019 and began to build application integration software in December 2019. For the founding team, it was not their first foray into building a tech software company from scratch in Sioux Falls. The first company Zuercher Technologies was acquired by TriTech Software Systems, the largest public safety software company in the world, in 2015. The difference this time arrived 3 months after starting up in the form of a global pandemic. So what changed that allowed Prismatic to be successful during unprecedented times?"), "\n", React.createElement(_components.p, null, "Read the full story on ", React.createElement(_components.a, {
    href: "https://mug.news/prismatic-launching-a-product-in-a-pandemic/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Mug.News")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
